import { createRouter, createWebHistory } from 'vue-router'

//路由守卫中需要使用LocalStorage
import Storage from './models/storage'

//登录组件
// import Login from './components/Login/Login.vue'

//后台管理（后台管理会有许多子页面）
// import Admin from './components/Admin.vue'

//个人空间————用户管理
// import UserManage from './components/PersonalSpace/UserManage.vue'

//个人空间————任务管理
// import TaskManage from './components/PersonalSpace/TaskManage.vue'

//个人空间————任务点查看
// import MapFindPoint from './components/PersonalSpace/MapFindPoint.vue'

//个人空间————消息通告
// import MessageManage from './components/PersonalSpace/MessageManage.vue'

//个人空间————上传文件
// import UploadFile from './components/PersonalSpace/UploadFile.vue'

//个人空间————上传列表
// import UploadTable from './components/PersonalSpace/UploadTable.vue'

//智慧巡湾
// import WisdomCruiseBay from './components/WisdomCruiseBay/WisdomCruiseBay.vue'

//鸟瞰东疆
// import BirdSeeEasternTerritory from './components/BirdSeeEasternTerritory/BirdSeeEasternTerritory.vue'

//统计报表————发现报表
// import DiscoveryReport from './components/StatisticalReport/DiscoveryReport.vue'

//统计报表————清理报表
// import CleannessReport from './components/StatisticalReport/CleannessReport.vue'

//统计报表————生成周报
// import WeeklyReport from './components/StatisticalReport/WeeklyReport.vue'

//人工核查
// import ManualCheck from './components/ManualCheck/ManualCheck.vue'

//预测模块
// import Prediction from './components/Prediction/Prediction.vue'

//魅力东疆
// import CharmingEasternTerritory from './components/CharmingEasternTerritory/CharmingEasternTerritory.vue'

//游客查看魅力东疆模块
// import TouristCharmingEastern from './components/TouristCharmingEastern.vue'

//移动端页面-------------------------------------------------------------------------------------------------

//魅力东疆
// import CharmingEasternTerritoryMobile from "./components/Mobile/CharmingEasternTerritory.vue"

//用户管理
// import UserManageMobile from './components/Mobile/UserManage.vue'

//任务管理
// import TaskManageMobile from './components/Mobile/TaskManage.vue'

//消息通告
// import MessageManageMobile from './components/Mobile/MessageManage.vue'

//游客浏览魅力东疆
// import TouristCharmingEasternMobile from './components/Mobile/TouristCharmingEastern.vue'

//清理报表
// import CleannessReportMobile from './components/Mobile/CleannessReport.vue'

//额外的识别垃圾页面
// import DistinguishRubbish from './components/Mobile/DistinguishRubbish.vue'

//鸟瞰东疆简化版
// import BirdSeeEasternTerritoryMobile from "./components/Mobile/BirdSeeEasternTerritory.vue"

//移动端主页 管理员/工作人员/领导
// import Home from './components/Mobile/Home.vue'

//移动端的导航栏
// import Mobile from './components/Mobile/navigator.vue'

//配置路由
const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/login', component: () => import('./components/Login/Login.vue') }, //登录页
    { path: '/touristCharmingEastern', component: () => import('./components/TouristCharmingEastern.vue') }, //游客查看魅力东疆
    {
      path: '/home',
      component: () => import('./components/Mobile/Home.vue')
    },
    {
      path: '/',
      component: () => import('./components/Admin.vue'),
      children: [
        { path: '/', component: () => import('./components/PersonalSpace/UserManage.vue') }, //个人空间————用户管理
        { path: '/taskManage', component: () => import('./components/PersonalSpace/TaskManage.vue') }, //个人空间————任务管理
        { path: '/mapFindPoint', component: () => import('./components/PersonalSpace/MapFindPoint.vue')}, //任务点查看
        { path: '/messageManage', component: () => import('./components/PersonalSpace/MessageManage.vue') }, //个人空间————消息通告
        { path: '/uploadFile', component: () => import('./components/PersonalSpace/UploadFile.vue') }, //个人空间————上传文件
        { path: '/uploadTable', component: () => import('./components/PersonalSpace/UploadTable.vue') }, //个人空间————上传列表
        { path: '/wisdomCruiseBay', component: () => import('./components/WisdomCruiseBay/WisdomCruiseBay.vue') }, //智慧巡湾
        {
          path: '/birdSeeEasternTerritory',
          component: () => import('./components/BirdSeeEasternTerritory/BirdSeeEasternTerritory.vue')
        }, //鸟瞰东疆
        {
          path: '/discoveryReport',
          component: () => import('./components/StatisticalReport/DiscoveryReport.vue')
        }, //统计报表————发现报表
        {
          path: '/cleannessReport',
          component: () => import('./components/StatisticalReport/CleannessReport.vue')
        }, //统计报表————清理报表
        { path: '/weeklyReport', component: () => import('./components/StatisticalReport/WeeklyReport.vue') }, //统计报表————生成周报
        { path: '/manualCheck', component: () => import('./components/ManualCheck/ManualCheck.vue') }, //人工核查
        { path: '/prediction', component: () => import('./components/Prediction/Prediction.vue') }, //预测模块
        {
          path: '/charmingEasternTerritory',
          component: () => import('./components/CharmingEasternTerritory/CharmingEasternTerritory.vue')
        } //魅力东疆
      ]
    },
    {
      path: '/touristCharmingEastMobile',
      component: () => import('./components/Mobile/TouristCharmingEastern.vue')
    },
    {
      path: '/distinguish-rubbish',
      component: () => import('./components/Mobile/DistinguishRubbish.vue')
    },
    {
      path: '/mobile',
      component: () => import('./components/Mobile/navigator.vue'),
      children: [
        {
          path: 'userManage', 
          component: () => import('./components/Mobile/UserManage.vue')
        }, 
        { 
          path: 'taskManage', 
          component: () => import('./components/Mobile/TaskManage.vue')
        }, 
        { 
          path: 'messageManage', 
          component: () => import('./components/Mobile/MessageManage.vue')
        },
        {
          path: 'cleannessReport',
          component: () => import('./components/Mobile/CleannessReport.vue')
        },
        {
          path: 'charmingEasternTerritory',
          component: () => import("./components/Mobile/CharmingEasternTerritory.vue")
        },
        {
          path: "birdSeeEasternTerritory",
          component: () => import("./components/Mobile/BirdSeeEasternTerritory.vue")
        }
      ]
    }
  ]
})

// 路由守卫
router.beforeEach((to, from, next) => {
  /*
      to: Route: 即将要进入的目标 路由对象  
      from: Route: 当前导航正要离开的路由   
      next(false): 中断当前的导航。
      next()  路由继续向下匹配
      next('/') 或者 next({ path: '/' }) 可以跳转路由
    */
  if (to.path === '/mapFindPoint' || to.path === "/mobile/birdSeeEasternTerritory") {
    next();
  }
  if (
    to.path === '/login' ||
    to.path === '/touristCharmingEastern' ||
    to.path === '/touristCharmingEastMobile' ||
    to.path === '/distinguish-rubbish'
  ) {
    //如果是登录页，游客查看魅力东疆，领导查看鸟瞰东疆就允许直接跳转
    next();
  } else {
    //否则就需要根据token进行判断
    let userinfo = Storage.get('userinfo')
    if (userinfo && userinfo.data.token != '') {
      next();
    } else {
      next({ path: '/login' });
    }
  }
})

export default router
