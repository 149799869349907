import { createApp } from 'vue'
import App from './App.vue'
import router from './route'
//配置antd
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

//黑色主题
// import 'ant-design-vue/dist/antd.dark.css'

//axios配置请求数据
import { AxiosInstance } from 'axios'
import Axios from 'axios'
//引入storage
import Storage, { StorageInstance } from './models/storage'
import 'lib-flexible'

//全局配置Axios
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance //这个就是个接口
    $storage: StorageInstance
  }
}
//全局配置storage
(window as any).IS_MOBILE = false
{
  if (navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)) {
    (window as any).IS_MOBILE = true;
  }
}
console.log((window as any).IS_MOBILE)

let app = createApp(App)
app.config.globalProperties.$axios = Axios //this.Axios
app.config.globalProperties.$storage = Storage //this.storage
app.use(router)
app.use(Antd)
// 全局配置axios拦截器
Axios.interceptors.response.use(
  response => {
    if(response.data.status === 403 ) {
      alert("该用户在其他地点登录，请重新登录！");
      router.push({
        path: "/login"    
      });
    }
    return response;
  }
)
app.mount('#app')
