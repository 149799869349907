<template>
  <router-view></router-view>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'App'
});
</script>

<style>
html,
body, #root {
  height: 100%;
  overflow-x: hidden;
}
#app {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-width: 15.8148rem;
}
@media screen and (max-width: 800px) {
  .ant-table tr,
  th, td {
    padding: 0.05rem !important;
  }
  #feature-pic {
    width: 12.963rem !important;
    height: 7.4074rem !important;
  }
  .delete-box {
    z-index: 2;
  }
}
</style>
